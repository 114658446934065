import { Language, Script, Song } from '@/types/song';
import Dexie from 'dexie';

interface SearchIndex {
  songNumber: number;
  language: Language;
  script: Script;
  searchText: string;
}

/**
 * keep only lowercase alphanumeric characters for flexible searching
 */
const searchable = (text: string) =>
  text.replace(/[^\p{L}\p{N}]+/gu, '').toLowerCase();

export default class SongDatabase extends Dexie {
  private songs!: Dexie.Table<Song, number>;
  private searchIndex!: Dexie.Table<SearchIndex, number>;

  constructor() {
    super('SongDatabase');
    this.version(1).stores({
      songs: '[songNumber+language]',
      searchIndex: '[songNumber+language+script]',
    });
  }

  async addSongs(songs: Song[]): Promise<void> {
    await this.songs.bulkPut(songs);
    const searchIndex: SearchIndex[] = [];

    songs.forEach((song) =>
      Object.entries(song.lyrics).forEach(([script, lyrics]) => {
        searchIndex.push({
          songNumber: song.songNumber,
          language: song.language,
          script: script as Script,
          searchText: searchable(lyrics?.join() || ''),
        });
      })
    );

    await this.searchIndex.bulkPut(searchIndex);
  }

  async getSong(songNumber: number, language: Language): Promise<Song | null> {
    return (await this.songs.get({ songNumber, language })) || null;
  }

  async findSongs(songNumberOrSearchTerm: number | string): Promise<Song[]> {
    if (typeof songNumberOrSearchTerm === 'number') {
      return this.findSongsByNumber(songNumberOrSearchTerm);
    }

    return this.findSongsBySearchText(songNumberOrSearchTerm);
  }

  private async findSongsByNumber(songNumber: number) {
    return this.songs.where({ songNumber }).toArray();
  }

  private async findSongsBySearchText(text: string) {
    const found = await this.searchIndex
      .filter((searchIndex) =>
        searchIndex.searchText.includes(searchable(text))
      )
      .toArray();

    return this.songs
      .where('[songNumber+language]')
      .anyOf(
        found.map((searchIndex) => [
          searchIndex.songNumber,
          searchIndex.language,
        ])
      )
      .toArray();
  }
}
