
import { defineComponent } from 'vue';

export default defineComponent({
  props: ['modelValue'],
  setup(props, context) {
    const increase = () => {
      context.emit('update:modelValue', props.modelValue + 1);
    };

    const decrease = () => {
      context.emit('update:modelValue', Math.max(props.modelValue - 1, 0));
    };

    return { increase, decrease };
  },
});
