import { createRouter, createWebHashHistory, RouteRecordRaw } from 'vue-router';
import Song from '../views/Song.vue';

const routes: Array<RouteRecordRaw> = [
  {
    path: '/song/:language/:number',
    name: 'Song',
    component: Song,
  },
  {
    path: '/:pathMatch(.*)*',
    redirect: '/song/malayalam/1',
  },
];

const router = createRouter({
  history: createWebHashHistory(),
  routes,
});

export default router;
