
import Lyrics, { Paragraph } from '@/components/Lyrics.vue';
import Search from '@/components/Search.vue';
import FontSelector from '@/components/FontSelector.vue';
import useSongStore from '@/composables/use-song-store';
import { SearchResult } from '@/song';
import { Language } from '@/types/song';
import { computed, defineComponent, ref, watch } from 'vue';
import { RouteLocationNormalizedLoaded, useRoute, useRouter } from 'vue-router';

const getSongFromRoute = (route: RouteLocationNormalizedLoaded) => {
  return {
    language: route.params.language as Language,
    number: Number(route.params.number as string),
  };
};

const toParagraph = (lyrics: string[] | undefined): Paragraph[] | null => {
  return (
    lyrics?.map((para, i) => ({
      number: i + 1,
      text: para,
    })) || null
  );
};

const addParagraphNumbers = (paragraphs: Paragraph[] | null) => {
  if (!paragraphs) {
    return null;
  }

  let paragraphNumber = 1;

  return paragraphs.map((paragraph) => {
    const isChorus = /^Chorus:\n/.test(paragraph.text);

    if (!isChorus) {
      return {
        ...paragraph,
        text: paragraph.text.replace(/^(\d+\.?)?/, `${paragraphNumber++}. `),
      };
    } else {
      return paragraph;
    }
  });
};

export default defineComponent({
  name: 'Song',
  setup() {
    const fontSize = ref<number>(30);
    const route = useRoute();
    const router = useRouter();
    const { song, get } = useSongStore();
    const { language, number } = getSongFromRoute(route);

    const title = computed(
      () => song.value?.titles?.manglish || song.value?.titles?.english
    );
    const songNumber = computed(() => song.value?.songNumber);
    const lyrics = computed(() => {
      if (song.value?.language === Language.Malayalam) {
        return {
          left: toParagraph(song.value?.lyrics?.malayalam),
          right: toParagraph(song.value?.lyrics?.manglish),
        };
      } else {
        return {
          left: addParagraphNumbers(toParagraph(song.value?.lyrics?.english)),
          right: null,
        };
      }
    });

    get(language, number);

    watch(
      () => getSongFromRoute(route),
      ({ language, number }) => {
        get(language, number);
      }
    );

    const openSong = (song: SearchResult) => {
      router.push(`/song/${song.language}/${song.songNumber}`);
    };

    return { openSong, songNumber, title, lyrics, fontSize };
  },
  components: { Lyrics, Search, FontSelector },
});
