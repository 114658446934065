import { createElementVNode as _createElementVNode, vModelText as _vModelText, withModifiers as _withModifiers, withDirectives as _withDirectives, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-30a9e490"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("img", {
      class: "search-button",
      src: "/assets/search.png",
      onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.toggleSearch && _ctx.toggleSearch(...args)))
    }),
    (_ctx.searchVisible)
      ? (_openBlock(), _createElementBlock("form", {
          key: 0,
          class: "search-form",
          autocomplete: "off",
          onClick: _cache[4] || (_cache[4] = 
//@ts-ignore
(...args) => (_ctx.toggleSearch && _ctx.toggleSearch(...args))),
          onSubmit: _cache[5] || (_cache[5] = _withModifiers(() => {}, ["prevent"]))
        }, [
          _withDirectives(_createElementVNode("input", {
            ref: "searchInput",
            class: "search",
            type: "text",
            placeholder: "Song number or lyrics",
            name: "searchTerm",
            "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.searchTerm) = $event)),
            onClick: _cache[2] || (_cache[2] = _withModifiers(() => {}, ["stop"]))
          }, null, 512), [
            [_vModelText, _ctx.searchTerm]
          ]),
          (_ctx.suggestions.length)
            ? (_openBlock(), _createElementBlock("div", {
                key: 0,
                class: "suggestions",
                onClick: _cache[3] || (_cache[3] = _withModifiers(() => {}, ["stop"]))
              }, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.suggestions, (suggestion) => {
                  return (_openBlock(), _createElementBlock("p", {
                    class: "suggestion",
                    key: suggestion.songNumber + suggestion.language,
                    onClick: ($event: any) => (_ctx.onSuggestionClick(suggestion))
                  }, _toDisplayString(_ctx.renderSearchResult(suggestion)), 9, _hoisted_1))
                }), 128))
              ]))
            : _createCommentVNode("", true)
        ], 32))
      : _createCommentVNode("", true)
  ], 64))
}