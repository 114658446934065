import { Language, Song } from '@/types/song';
import { SearchResult, SongStore } from '.';
import axios from 'axios';
import { EventEmitter } from 'events';
import TypedEmitter from 'typed-emitter';
import SongDatabase from './db';

type SongJson = Record<Language, { [key in string]: Song }>;

const LOCAL_STORE_DB_INITIALIZED = 'is_db_initialized';
const LOCAL_STORE_SONGS_VERSION = 'songs_version';
const SONG_JSON_PATH = '/lyrics.json';

export class IndexedDbSongStore implements SongStore {
  private isReady = false;
  private db = new SongDatabase();
  private eventEmitter = new EventEmitter() as TypedEmitter<{
    ready: () => void;
  }>;

  constructor() {
    this.startup();
  }

  async find(searchTermOrSongNumber: string | number): Promise<SearchResult[]> {
    await this.whenReady();
    return this.db.findSongs(searchTermOrSongNumber);
  }

  listTitles(): Promise<string[]> {
    throw new Error('Method not implemented.');
  }

  async get(language: Language, songNumber: number): Promise<Song> {
    await this.whenReady();

    const song = await this.db.getSong(songNumber, language);

    if (!song) {
      throw new Error(`Song (${songNumber}, ${language}) not found`);
    }

    return song;
  }

  private async startup() {
    const dbInitialized = this.isDbInitialized();

    if (dbInitialized) {
      console.log('Database ready');
      this.ready();
    }

    const songJsonChanged = await this.hasSongJsonChanged();

    if (!songJsonChanged && dbInitialized) {
      console.log('Cached version of songs is latest version');
      this.ready();
      return;
    }

    console.log('Cached version of songs is outdated. Updating cached songs');

    const songJson = await this.getSongJson();
    await this.updateDb(songJson);

    this.setDbInitialized(true);
    this.ready();

    console.log('Cache updated');
  }

  private isDbInitialized() {
    return localStorage.getItem(LOCAL_STORE_DB_INITIALIZED) === 'true';
  }

  private setDbInitialized(isInitialized: boolean) {
    localStorage.setItem(
      LOCAL_STORE_DB_INITIALIZED,
      JSON.stringify(isInitialized)
    );
  }

  private ready() {
    this.isReady = true;
    this.eventEmitter.emit('ready');
  }

  private async whenReady(): Promise<void> {
    if (this.isReady) {
      return;
    }

    await new Promise<void>((resolve) =>
      this.eventEmitter.on('ready', resolve)
    );
  }

  private async updateDb(songJson: SongJson) {
    const songs = Object.values(songJson).flatMap((songs) =>
      Object.values(songs)
    );

    await this.db.addSongs(songs);
  }

  private async hasSongJsonChanged() {
    const latestVersion = await this.getLatestVersion();
    const cachedVersion = this.getCachedVersion();

    return !latestVersion || !cachedVersion || latestVersion !== cachedVersion;
  }

  private async getLatestVersion() {
    const response = await axios.head(SONG_JSON_PATH);
    return response.headers.etag || null;
  }

  private getCachedVersion() {
    return localStorage.getItem(LOCAL_STORE_SONGS_VERSION) || null;
  }

  private async getSongJson() {
    const response = await axios.get<SongJson>(SONG_JSON_PATH);
    const songJson = response.data;
    const version = response.headers.etag || null;

    version && localStorage.setItem(LOCAL_STORE_SONGS_VERSION, version);

    return songJson;
  }
}
