import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-cea6dc44"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "song-container" }
const _hoisted_2 = {
  key: 0,
  class: "title"
}
const _hoisted_3 = { class: "lyrics-container" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Search = _resolveComponent("Search")!
  const _component_FontSelector = _resolveComponent("FontSelector")!
  const _component_Lyrics = _resolveComponent("Lyrics")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("header", null, [
      (_ctx.title)
        ? (_openBlock(), _createElementBlock("p", _hoisted_2, _toDisplayString(`#${_ctx.songNumber} - ${_ctx.title}`), 1))
        : _createCommentVNode("", true),
      _createVNode(_component_Search, { onOpen: _ctx.openSong }, null, 8, ["onOpen"]),
      _createVNode(_component_FontSelector, {
        modelValue: _ctx.fontSize,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.fontSize) = $event))
      }, null, 8, ["modelValue"])
    ]),
    _createElementVNode("div", _hoisted_3, [
      (_ctx.lyrics.left)
        ? (_openBlock(), _createBlock(_component_Lyrics, {
            key: 0,
            class: _normalizeClass({ lyrics: true, 'lyrics-indented': !_ctx.lyrics.right }),
            lyrics: _ctx.lyrics.left,
            fontSize: _ctx.fontSize
          }, null, 8, ["class", "lyrics", "fontSize"]))
        : _createCommentVNode("", true),
      (_ctx.lyrics.right)
        ? (_openBlock(), _createBlock(_component_Lyrics, {
            key: 1,
            class: "lyrics",
            lyrics: _ctx.lyrics.right,
            fontSize: _ctx.fontSize
          }, null, 8, ["lyrics", "fontSize"]))
        : _createCommentVNode("", true)
    ])
  ]))
}