import { SearchResult } from '@/song';
import { IndexedDbSongStore } from '@/song/indexeddb-song-store';
import { Language, Song } from '@/types/song';
import { ref, Ref } from 'vue';

const songStore = new IndexedDbSongStore();

const isValidSearch = (searchTerm: string) => !!searchTerm;

export default (): {
  song: Ref<Song | null>;
  searchResults: Ref<SearchResult[]>;
  get: (language: Language, number: number) => void;
  find: (searchTerm: string) => void;
} => {
  const songRef = ref<Song | null>(null);
  const searchResults = ref<SearchResult[]>([]);

  const get = (language: Language, number: number) => {
    songStore.get(language, number).then((song) => (songRef.value = song));
  };

  const find = async (searchTerm: string) => {
    if (!isValidSearch(searchTerm)) {
      searchResults.value = [];
      return;
    }

    const results = await songStore.find(parseInt(searchTerm) || searchTerm);
    searchResults.value = results;
  };

  return { song: songRef, searchResults, get, find };
};
