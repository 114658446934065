
import { computed, defineComponent, ref, watch } from 'vue';

export type Paragraph = { number: number; text: string };

export default defineComponent({
  props: ['lyrics', 'fontSize'],
  setup(props) {
    const pinnedParagraph = ref<Paragraph | null>(null);
    const fontSizePixels = computed(() =>
      props['fontSize'] ? `${props['fontSize']}px` : null
    );

    const onParaClick = (paragraph: Paragraph) => {
      if (pinnedParagraph.value?.number === paragraph.number) {
        pinnedParagraph.value = null;
      } else {
        pinnedParagraph.value = paragraph;
      }
    };

    watch(
      () => props.lyrics,
      () => (pinnedParagraph.value = null)
    );

    return { pinnedParagraph, onParaClick, fontSizePixels };
  },
});
