export type Song = {
  titles: Titles;
  language: Language;
  songNumber: number;
  lyrics: Lyrics;
};

export type Titles = Partial<Record<Script, string>>;

export type Lyrics = Partial<Record<Script, string[]>>;

export enum Script {
  English = 'english',
  Malayalam = 'malayalam',
  Manglish = 'manglish',
}

export enum Language {
  English = 'english',
  Malayalam = 'malayalam',
}
