import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, normalizeStyle as _normalizeStyle, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-6eeef9cc"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "lyrics-container" }
const _hoisted_2 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.lyrics, (paragraph) => {
      return (_openBlock(), _createElementBlock("div", {
        class: _normalizeClass({
        paragraph: true,
        'paragraph-pinned': _ctx.pinnedParagraph?.number === paragraph.number,
      }),
        key: paragraph.number,
        onClick: ($event: any) => (_ctx.onParaClick(paragraph))
      }, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(paragraph.text.split('\n'), (line, index) => {
          return (_openBlock(), _createElementBlock("p", {
            class: "line",
            style: _normalizeStyle({ 'font-size': _ctx.fontSizePixels || '30px' }),
            key: index
          }, _toDisplayString(line), 5))
        }), 128))
      ], 10, _hoisted_2))
    }), 128))
  ]))
}