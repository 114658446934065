
import useSongStore from '@/composables/use-song-store';
import { SearchResult } from '@/song';
import { defineComponent, ref, watch, nextTick } from 'vue';

let timer: number | null = null;
const delay = (task: () => void, timeout: number) => {
  if (timer) {
    clearTimeout(timer);
  }

  timer = setTimeout(task, timeout);
};

const renderSearchResult = (result: SearchResult) =>
  `${result.songNumber} ${Object.values(result.titles).join(' / ')}`;

export default defineComponent({
  setup(props, context) {
    const searchTerm = ref('');
    const searchInput = ref<HTMLInputElement | null>(null);
    const searchVisible = ref(false);
    const { find, searchResults } = useSongStore();

    watch(searchTerm, (searchTerm) => {
      delay(() => find(searchTerm), 1000);
    });

    const toggleSearch = () => {
      searchVisible.value = !searchVisible.value;
      nextTick(() => searchInput.value?.focus());
    };

    const onSuggestionClick = (suggestion: SearchResult) => {
      context.emit('open', suggestion);
      toggleSearch();
      searchTerm.value = '';
    };

    return {
      searchVisible,
      searchInput,
      close,
      onSuggestionClick,
      toggleSearch,
      searchTerm,
      suggestions: searchResults,
      renderSearchResult,
    };
  },
});
